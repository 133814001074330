@media (max-width:1199px){
	.fixedElement #nav{
	  padding: .5em 2em !important;
	}
	.fixedElement #nav #navbar-nav ul li{
	  padding: 0em .5em;
	}
	.smallNavBar img{
	  display: block;
	}
	.smallNavBar{
	  top:0em;
	  left:0em;
	  position: fixed;
	  width:100%;
	}
	.smallNavBar #nav{
		padding: .5em 2em !important;
	}
	.jumbo-header{
		height: 100vh;
		display: flex;
		/*align-items: center;*/
	}
	#header-main {
  		padding-top: 20px !important;
  	}
  	.header-title-section{
  		z-index: 2;
  		height: 50vh;
  		width: 200% !important;
  	}
  	 .header-title-section{
  		z-index: 2;
  		text-align: center;
  		vertical-align: middle;
  		margin-top: 3em;
  	}
  	.header-sub-section{
		display: flex;
		justify-content: center;
	}
  	.header-img {
  		position: absolute;
  		height: 50%;
  		top: 20%;
  		opacity: .3;
  		width: 100%;
  	}
  	.jumbo-doctors{
		padding-bottom: 0px;
	}
	.doctor-story{
	  width: 50%;
	}
	.doctor-image-area{
	  width: 50%;
	  height: 15vh;
	  opacity: .9;
	}
	/*.doctor-column-1, .doctor-column-2{
	  height: 100%;
	  border-radius: 100px;
	}
	.doctor-column-1{
	  background-image: url(../images/tim.png);
	  background-repeat: no-repeat;
	  background-position: right bottom;
	  background-size: 50%;
	  opacity: .9;
	}
	.doctor-column-2{
	  background-image: url(../images/jim.png);
	  background-repeat: no-repeat;
	  background-position: left bottom;
	  background-size: 40%;
	  opacity: .9;
	}*/
}

@media (max-width:991px){
	#header-main {
  		padding-top: 20px !important;
  	}
  	.header-title-section{
  		z-index: 2;
  		text-align: center;
  		vertical-align: middle;
  		margin-top: 3em;
  	}
  	.header-sub-section{
		display: flex;
		justify-content: center;
	}
  	.header-img {
  		position: absolute;
  		height: 50%;
  		top: 20%;
  		opacity: .3;
  	}
  	.jumbo-doctors{
		padding-bottom: 0px;
	}
	.doctor-story{
	  width: 50%;
	}
	.doctor-image-area{
	  width: 50%;
	  height: 20vh;
	  opacity: .9;
	}
	/*.doctor-column-1, .doctor-column-2{
	  height: 100%;
	  border-radius: 100px;
	}
	.doctor-column-1{
	  background-image: url(../images/tim.png);
	  background-repeat: no-repeat;
	  background-position: right bottom;
	  background-size: 55%;
	  opacity: .9;
	}
	.doctor-column-2{
	  background-image: url(../images/jim.png);
	  background-repeat: no-repeat;
	  background-position: left bottom;
	  background-size: 45%;
	  opacity: .9;
	}
	.doctor-title{
		position: absolute;
		font-size: 10px;
		color: gray;
		opacity: 1;
		writing-mode: vertical-rl;
		text-orientation: mixed;
	}
	.doctor-column-1 .doctor-title{
		top: 60px;
		left: 60px;
	}
	.doctor-column-2 .doctor-title{
		top: 60px;
		right: 60px;
	}*/
}

@media (max-width:767px){

}

@media(max-width:576px){
	
}

@media (max-width:450px){
	#header-sub {
		letter-spacing: 1px;
		font-size: 1.5em
	}
	.header-sub-section{
		display: flex;
		justify-content: center;
	}
	#header-main{
		font-size: 4em;
	}
	.header-img{
		padding: 0px;
	}
	.header-arrow-section{
		left: 45%;
	}
	.fixedElement #nav{
	  padding: .5em 1em !important;
	}
	.fixedElement #nav #navbar-nav ul li{
	  padding: 0em .5em;
	}
	.smallNavBar img{
	  display: block;
	}
	.smallNavBar{
	  top:0em;
	  left:0em;
	  position: fixed;
	  width:100%;
	}
	.smallNavBar #nav{
		padding: .5em 1em !important;
	}
	.jumbo-doctors{
		padding-bottom: 0px;
	}
	.column {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}
	.doctor-story{
	  width: 50%;
	}
	.doctor-header h2{
		font-size: 25px;
		margin-left: 4px;
	  	margin-right: 0px !important;
	}
	.doctor-image-div{
	  width: 5em;
	  /*height: 14em;*/
	}
	.doctor-image-area{
		width: 100%;
		height: 15vh;
		opacity: .9;
	}
	.doctor-column-1 img,
	.doctor-column-2 img{
		width: 100%;
		height: 20vh;
	}
	.doctor-column-1{
		margin-top: 0px;
	}
	.doctor-column-2{
		margin-top: 15px;
	}
	.doctor-title{
		position: absolute;
		font-size: 5px;
		opacity: 1;
		writing-mode: vertical-rl;
		text-orientation: mixed;
		padding: 0px !important;
		margin: 0px;
	}
	.doctor-column-1 .doctor-title{
		top: 20%;
		left: 15%;
	}
	.doctor-column-2 .doctor-title{
	  	top: 30%;
	  	right: 20%;
	}

	/*.doctor-column-1, .doctor-column-2{
	  	height: 100%;
	  	border-radius: 100px;
	}
	.doctor-column-1{
	  	background-image: url(../images/tim.png);
	  	background-repeat: no-repeat;
	  	background-position: right bottom;
	  	background-size: 65%;
	  	opacity: .9;
	}
	.doctor-column-2{
	  	background-image: url(../images/jim.png);
	  	background-repeat: no-repeat;
	  	background-position: left bottom;
	  	background-size: 53%;
	  	opacity: .9;
	}
	.doctor-title{
		position: absolute;
		font-size: 5px;
		color: gray;
		opacity: 1;
		writing-mode: vertical-rl;
		text-orientation: mixed;
	}
	.doctor-column-1 .doctor-title{
		top: 10px;
		left: 20px;
	}
	.doctor-column-2 .doctor-title{
		top: 40px;
		right: 30px;
	}*/

	.contact-img {
		display: none;
	}
}


/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}