/*----------Navbar Section----------*/

.fixedElement #nav{
  background-color: #fff;
  position: fixed;
  top:0em;
  width:100%;
  z-index:100;
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibilite: hidden;
}

.fixedElement #nav #navbar ul li{
  padding: 0em .5em;
}

.smallNavBar{
  background-color: none;
  position: absolute;
  top:30em;
  left:6em;
  width:45%;
  z-index:100;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibilite: hidden;
}

.smallNavBar img{
  display: none;
}

#navbar .navbar-nav .nav-link{
  font-size: .8em;
  font-weight: bold;
  letter-spacing: .2em;
  color: gray;
}

#nav{
  background-color: none;
  width: 100%;
  box-shadow: 0 0 0 0;
}

#logo {
  width: 3em;
  height: 3em;
}

#navbar .navbar-nav #nav-header:hover,
.fixedElement .navbar-nav #nav-header.active{
  border-left: 2px solid rgb(200,46,48);
  color: rgb(200,46,48);
  /*transition: 1s;*/
}

#navbar .navbar-nav #nav-about:hover,
.fixedElement .navbar-nav #nav-about.active{
  border-left: 2px solid rgb(38,140,90);
  color: rgb(38,140,90);
  /*transition: 1s;*/
}

#navbar .navbar-nav #nav-services:hover,
.fixedElement .navbar-nav #nav-services.active{
  border-left: 2px solid rgb(30,82,132);
  color: rgb(30,82,132);
  /*transition: 1s;*/
}

#navbar .navbar-nav #nav-contact:hover,
.fixedElement .navbar-nav #nav-contact.active{
  border-left: 2px solid rgb(255,213,57);
  color: rgb(255,213,57);
  /*transition: 1s;*/
}

/*.navbar .navbar-nav .nav-item .nav-link:before {
  content: "";
  background: #fff;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0px;
  bottom: 0px;
  width: 0%;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}*/

/*.navbar .navbar-nav .nav-item .nav-link.active:before, .navbar .navbar-nav .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
}*/

/*----------Hamburger----------*/
.icon-bar {
  width: 22px; 
  height: 2px;
  background-color: #B6B6B6;
  display: block;
  transition: all 0.2s;
  margin-top: 4px
}
 
.navbar-toggler {
  border: none;
  background: transparent !important;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
 
.navbar-toggler .middle-bar {
  opacity: 0;
}
 
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
 
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
 
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
 
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

/*----------Header Section----------*/


.jumbo-header{
	background-color: #f8f8ff;
/*  background-image: url('../images/colored-wood.jpg');
  background-attachment: local;
  background-position: contain;
  background-size: 50%;*/
  /*background-repeat: no-repeat;*/
	height: 100vh;
	border-radius: 0;
	box-shadow: none;
  position: relative;
  padding-left: 8%;
  /*opacity: .9;*/
}

.header-title-section, .header-img {
	height: 100%;
}

.header-img {
	background-image: url('../images/finallogo.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
  opacity: .8;
  /*box-shadow: 0px 0px 5px #fff;*/
}

.header-title, .about-title, .about-sub,
.service-title{
	color: #808080;
  /*font-family: 'Quicksand', sans-serif;*/
}

.header-title-section h1{
	font-size: 5em;
  background-color: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255,255,255,0.5) 0px 3px 3px;
}

.header-title-section #header-sub{
  width: 50%;
  text-shadow: rgba(255,255,255, 1) 0px 3px 3px;
  opacity: .9;
}

#header-main{
  font-size: 6.5em;
}

.header-arrow-section {
  position: absolute;
  bottom: 10%;
  left: 50%;
  color: #313639;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: #808080;
  text-shadow: rgba(255,255,255, 1) 0px 3px 3px;
}


/*----------Body Section----------*/

.jumbo-body{
  background-color: transparent;
  padding: 0;
  position: relative;
  /*z-index: 3;*/
  margin: -80px 30px 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.container-body{
  /*z-index: 12;*/
  /*background-color: #F7F7EF;*/
  background-color: rgba(255, 255, 255, .9);
  margin-bottom: 0px;
  margin: 0px !important;
  border-radius: 0px;
  font-family: 'Quicksand', sans-serif;
}


/*----------About Section----------*/

.jumbo-about{
	border-radius: 0;
	box-shadow: none;
  /*background-color: rgba(255,255,255, 0.3);*/
  background-color: transparent;
}

.about-sub {
	line-height: 1.6;
}

/*----------Services Section----------*/

.jumbo-services{
  border-radius: 0;
  box-shadow: none;
  /*background-color: #ECEAE4;*/
  overflow: auto;
  min-height: 100px;
  background-color: transparent;
}


/* Float four columns side by side */
/*.column {
  float: left;
  width: 33.33%;
  padding: 0 10px;

}*/

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
/*.row:after {
  content: "";
  display: table;
  clear: both;
}*/

/* Style the counter cards */
.card {
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  padding: 30px;
  text-align: center;
  height: 95%;
  opacity: 0.9;
  border-radius: 0px !important;
}

.card:hover {
    opacity: 1;
}

.card-title, .card-sub {
  color: #fffdf9;
  font-family: 'Quicksand', sans-serif;
}

.card-details img{
  opacity: .9;
}

.service-1 {
  background-color: #ef7a85;
}

.service-2{
  background-color: #ffc979;
}

.service-3 {
  background-color: #9f8b7d;
}

.service-4 {
  background-color: #a0c4e2;
}

.service-5 {
  background-color: #7a61a9;
}

.service-6 {
  background-color: #99c5c0;
}

/*----------Doctors Section----------*/

.jumbo-doctors{
  background-color: #fbfbf8;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0px;
  /*height: 50vh;*/
}

.doctor-header h2{
  color: #808080;
  font-family: 'Quicksand', sans-serif;
}

.doctor-story{
  width: 70%;
}

.doctor-image-area{
  /*width: 30%;*/
  /*height: 20vh;*/
  opacity: .8;
}

.doctor-image-div{
  width: 13em;
  /*height: 14em;*/
}
.doctor-column-1, .doctor-column-2{
  filter: contrast(140%);
}

.doctor-column-1{
  /*border-radius: 200%  10%;*/
}

.doctor-column-2{
  /*border-radius: 10% 200%;*/
}

.doctor-column-1, .doctor-column-2{ 
  /*background-image: url('../images/bg2.png');*/
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200% 100%; 
  /*opacity: .9;*/
  /*padding-top: 90px;*/
  /*margin-top: 100px;*/
}

.doctor-column-1 img{
  /*border-radius: 0px 0px 100px 50px;*/
  /*position: absolute;*/
  bottom: 0px;
  right: 0px;
  /*padding: 20px 0px 0px 0px !important;*/
  height: 30vh; 
  width: 100%;
  /*box-shadow: inset 0em 0em 9em rgb(172, 148, 60, .5);*/
  /*opacity: .9;*/
}

.doctor-column-1{
  /*background-color: rgb(172, 148, 60, .5);*/
}

.doctor-column-2 img{
  /*border-radius: 0px 0px 30px 100px;*/
  /*position: absolute;*/
  bottom: 0px;
  left: 0px;
  /*padding: 20px 0px 0px 0px !important;*/
  height: 30vh; 
  width: 80%;
  /*box-shadow: inset 5em 0em 5em rgb(32, 83, 134, .5);*/
  /*opacity: .9;*/
}

.doctor-column-2{
  /*background-color: rgb(32, 83, 134, .5);*/
}

.doctor-title{
  /*position: absolute;*/
  color: #808080;
  font-size: 10px;
  opacity: 1;
  letter-spacing: .1em;
  /*margin-top: 5em;*/

}

.doctor-column-1 .doctor-title{
  /*bottom: 0px;*/
  /*width: 70%;*/
}
.doctor-column-2 .doctor-title{
  /*bottom: 0px;*/
  /*width: 70%;*/
}

/*span {
  position: relative;
  display: block;
  cursor: pointer;
}*/

.doctor-title:before, .doctor-title:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  top: 90%;
  margin-top: -0.5px;
  background: blue;
}

.doctor-title:before {
  left: -2.5px;
}
.doctor-title:after {
  right: 2.5px;
  background: blue;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.doctor-title:hover:before {
  background: blue;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.doctor-title:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}


/*----------Contact Section----------*/

.jumbo-contact {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0px;
  height: 80vh;
}

.contact-title-section{
  color: #fffff2;
}

.contact-details-section{
  position: absolute;
  bottom: 0em;
}

/*.contact-img {
  background-image: url('../images/bg2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 100%; 
}*/

/*----------Footer Section----------*/

.footer {
/*  background-image: url('../images/colored-wood.jpg');
  background-attachment: local;
  background-position: contain;
  background-size: 50%;*/
  /*background-repeat: no-repeat;*/
  background-color: #f8f8ff;
  width: 100%;
  height: 20vh;
  opacity: .8;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.footer-sub{
  font-size: .6em;
}

.unicode{
  font-size: 5px;
}

#fb,
#messenger {
  color: #808080;
  /*color: #4267B2;*/
  /*color: #fffff2;*/
}


/*----------Messenger Section----------*/

.messenger-div{
  position: fixed;
  bottom: 0;
  left: 0;
}
#fb-root{
  z-index: 1000;
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
}