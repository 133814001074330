#header-sub {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .10em solid orange; /* The typwriter cursor */
  white-space: nowrap;  /*Keeps the content on a single line */
  margin: 0; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .01em; /* Adjust as needed */
  animation: 
    typing 5.5s steps(25, end),
    blink-caret .5s step-end infinite;
    animation-iteration-count: infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 60% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange }
}

.header-arrow-section {
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
	animation-duration: 2s;
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
}

.about-title, .about-sub,
.service-title, .row {
  animation-duration: 5s; 
}

.contact-title-section,
.doctor-header h2 {
  animation-duration: 5s; 
}

@media (max-width:1199px){
  #header-sub {
  margin: 0; /* Gives that scrolling effect as the typing happens */
}
  @keyframes typing {
    from { width: 0 }
    to { width: 78% }
  }
}

@media (max-width:991px){
  #header-sub {
    /*margin: 0 2em 0 3em;  Gives that scrolling effect as the typing happens */
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 50% }
  }
}

/*--------- For small devices ---------*/
@media (max-width:450px){
  #header-sub {
    text-align: center;
    margin: 0; /* Gives that scrolling effect as the typing happens */
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 95% }
  }
}

